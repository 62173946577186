<template>
  <div class="header-bar">
    <div class="logo-bar"
         :style="{'background':dataObj.logoBgPurity?dataObj.logoBgColor:'url('+echoImg(dataObj.logoBg)+'no-repeat)','height':dataObj.logoHeight+'px'}">
      <div class="logo-box"
           :style="{'width':dataObj.logoW+'px','height':dataObj.logoH+'px'}">
        <div class="logoImgBox"
             :class="dataObj.logoShow=='overspread'?'overspread':''"
             :style="{'padding-top':(dataObj.logoHeight-dataObj.logoH)/2+'px','textAlign':dataObj.logoShow}">
          <img class="logoImg" :src="echoImg(dataObj.logo)"/>
        </div>
      </div>
    </div>
    <div class="nav-bar" :style="{'background':dataObj.navBgPurity?dataObj.navBgColor:'url('+echoImg(dataObj.navBg)+')','height':dataObj.navH+'px'}">
      <div class="nav-box"
           :style="{'width':dataObj.navW+'px','height':dataObj.navH+'px'}">
        <ul class="ul" :style="{'lineHeight':dataObj.navH+'px'}">
          <template v-for="(item,index) in navList" @key="index">
            <li class="li" :key="index" v-if="index<dataObj.navNum"
                @click="selectNav(index)"
                @mouseenter="navMouseenter(index)"
                @mouseleave="navMouseLeave"
                :style="{'fontSize':dataObj.navFont+'px','color':navSelectIndex==index?'#fff':dataObj.navFontColor,'background':navSelectIndex==index||navHoverIndex==index?dataObj.navActiveColor:'',
                'width':dataObj.navMode==1?dataObj.navLiW+'px':'','flex':dataObj.navMode==2?1:''}">
              <span>{{item.name}}</span>
              <div class="menu" v-if="dataObj.navShowChildren&&item.children.length>0">
                <p class="" v-for="(dates,idxs) in item.children" :key="idxs"
                   @mouseenter="menuMouseenter(idxs)"
                   @mouseleave="menuMouseleave"
                   @click="menuClick(idxs)"
                   :style="{'background':menuActiveIndex==idxs?dataObj.navActiveColor:'','color':menuActiveIndex==idxs?'#fff':''}">
                  <span>{{dates.name}}</span>
                </p>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TemplateHeader",
  components: {},
  data() {
    return {
      dataObj:{
        logoHeight:200,//头部整体logo的高度
        logoBg:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/04/25/20230425_c88ee535cdbc4c6f994940b6f10a47f1.jpg',
        logoW:1200,//logo部分的宽度
        logoH:160,//logo部分的高度
        logoBgPurity:false,//logo部分是否为纯色
        logoBgColor:'#999',//logo部分为纯色的颜色
        logo:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/01/31/20230131_1350c763732e47969cab35c05907e2e7.png',//logo部分是背景图的图片地址
        logoShow:'left',//左、右、居中、铺满overspread

        navH:40,//导航栏的高度
        navBg:'1974ad48fd2349779497ae57b67147cf/WebAnnexFile/2023/04/25/20230425_c88ee535cdbc4c6f994940b6f10a47f1.jpg',
        navW:1200,//导航栏的宽度
        navBgPurity:true,//导航栏部分是否为纯色
        navBgColor:'rgb(34, 139, 194)',//导航栏部分为纯色的颜色
        navNum:9,//导航栏数量
        navMode:2,//导航栏模式，1表示设置宽度，2表示等比宽度
        navLiW:100,//导航栏设置宽度
        navFont:16,//导航栏字体大小
        navFontColor:'#fff',//导航栏字体颜色
        navActiveColor:'#f39d30',//导航栏选择颜色
        navShowChildren:true,//导航栏是否显示二级导航
      },
      navList:[
        {id:1,name:'首页',children:[]},
        {id:2,name:'导航栏一',children:[{id:11,name:'导航栏一-1'},{id:12,name:'导航栏一-2'},{id:13,name:'导航栏一-3'},{id:14,name:'导航栏一-4'}]},
        {id:3,name:'导航栏二',children:[{id:11,name:'导航栏二-1'},{id:12,name:'导航栏二-2'},{id:13,name:'导航栏二-3'},{id:14,name:'导航栏二-4'}]},
        {id:4,name:'导航栏三',children:[{id:11,name:'导航栏三-1'},{id:12,name:'导航栏三-2'},{id:13,name:'导航栏三-3'},{id:14,name:'导航栏三-4'}]},
        {id:5,name:'导航栏四',children:[{id:11,name:'导航栏四-1'},{id:12,name:'导航栏四-2'},{id:13,name:'导航栏四-3'},{id:14,name:'导航栏四-4'}]},
        {id:6,name:'导航栏五',children:[{id:11,name:'导航栏五-1'},{id:12,name:'导航栏五-2'},{id:13,name:'导航栏五-3'},{id:14,name:'导航栏五-4'}]},
        {id:7,name:'导航栏六',children:[{id:11,name:'导航栏六-1'},{id:12,name:'导航栏六-2'},{id:13,name:'导航栏六-3'},{id:14,name:'导航栏六-4'}]},
        {id:8,name:'导航栏七',children:[{id:11,name:'导航栏七-1'},{id:12,name:'导航栏七-2'},{id:13,name:'导航栏七-3'},{id:14,name:'导航栏七-4'}]},
        {id:9,name:'导航栏八',children:[{id:11,name:'导航栏八-1'},{id:12,name:'导航栏八-2'},{id:13,name:'导航栏八-3'},{id:14,name:'导航栏八-4'}]},
        {id:10,name:'导航栏九',children:[{id:11,name:'导航栏九-1'},{id:12,name:'导航栏九-2'},{id:13,name:'导航栏九-3'},{id:14,name:'导航栏九-4'}]},
        {id:11,name:'导航栏十',children:[{id:11,name:'导航栏十-1'},{id:12,name:'导航栏十-2'},{id:13,name:'导航栏十-3'},{id:14,name:'导航栏十-4'}]},
      ],
      navSelectIndex:0,//选中index
      navHoverIndex:null,//鼠标滑过index
      menuActiveIndex:null,//二级导航栏选择index
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  created() {

  },
  methods: {
    //选择导航栏
    selectNav(index){
      this.navSelectIndex = index
    },
    //鼠标移入导航栏
    navMouseenter(index){
      this.navHoverIndex = index
    },
    //鼠标移出导航栏
    navMouseLeave(){
      this.navHoverIndex = null
    },
    //二级导航栏鼠标移入事件
    menuMouseenter(index){
      this.menuActiveIndex = index
    },
    //二级导航栏鼠标移出事件
    menuMouseleave(){
      this.menuActiveIndex = null
    },
    //二级导航点击事件
    menuClick(){

    }
  },
};
</script>

<style lang="scss" scoped>
  .logo-bar{
    width: 100%;
    .logo-box{
      margin: 0 auto;
      .logoImgBox{
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
    }
  }
  .nav-bar{
    width: 100%;
    .nav-box{
      margin: 0 auto;
      .ul{
        display: flex;
        width: 100%;
        height: 100%;
        .li{
          position: relative;
          float: left;
          text-align: center;
          cursor: pointer;
          .menu{
            width: 100%;
            height: auto;
            position: absolute;
            left: 0px;
            display: none;
            z-index: 9999;
            overflow: hidden;
            padding: 5px 0;
            background: #fff;
            color: #333;
            p{
              text-align: center;
              line-height: 30px;
              height: 30px;
              cursor: pointer;
              overflow: hidden;
            }
          }
        }
        .li:hover{
          .menu{
            display: block;
          }
        }
      }
    }
  }
  .overspread{
    .logoImg{
      width: 100%;
      height: 100%;
    }
  }
</style>
